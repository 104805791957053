type queryParam = string | null | undefined;

const isValidStockSymbol = (symbol: queryParam) => {
  return typeof symbol === "string" && /^[a-zA-Z]+$/.test(symbol);
};

const isValidTwitterId = (id: queryParam) => {
  return typeof id === "string" && /^[0-9]+$/.test(id);
}

const isValidHandle = (id: queryParam) => {
  return typeof id === "string" && /^\w+$/.test(id);
}

const isValidPassword = (password: string): boolean => {
  if (password.length < 8) {
    return false
  }
  if (!/[a-z]/.test(password)) {
    return false
  }
  if (!/[A-Z]/.test(password)) {
    return false
  }
  if (!/[0-9]/.test(password)) {
    return false
  }
  const specialChars = ["^","$","*",".","[","]","{","}","(",")","?","-",'"',"!","@","#","%","&","/","\\",",",">","<","'",":",";","|","_","~","`","+","="]
  let hasSpecial: boolean = false
  const chars = password.split("")
  for (const char of chars) {
    if (specialChars.includes(char)) {
      hasSpecial = true
      break
    }
  }
  return hasSpecial
}

export { isValidStockSymbol, isValidTwitterId, isValidHandle, isValidPassword };
