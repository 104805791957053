import * as React from "react";
import {useContext, useState} from "react";
import { AuthenticatedUserData, UserContext } from "./WithAuth";
import { navigate } from "gatsby";
const AmazonCognitoIdentity = require('amazon-cognito-identity-js');
import { Dialog } from '@headlessui/react'
import {isValidPassword} from "../../util/validators";
import {CognitoUser} from "amazon-cognito-identity-js";
import Spinner from "../animation/Spinner";

type AccountUpdateStatus = {
  password: boolean;
  attributes: boolean;
}

const MyAccount = () => {
  const userData = useContext(UserContext) as AuthenticatedUserData

  const email = userData.cognitoSession.getIdToken().payload.email
  const name = userData.cognitoSession.getIdToken().payload.name
  const org = userData.cognitoSession.getIdToken().payload['custom:organization']

  const [currentPassword, setCurrentPassword] = useState<string | null>(null)
  const [accountUpdateStatus, setAccountUpdateStatus] = useState<AccountUpdateStatus>({
    password: false,
    attributes: false
  })
  const [passwordError, setPasswordError] = useState<boolean>(false)
  const [isOpenPassDialog, setIsOpenPassDialog] = useState<boolean>(false)
  const [isUpdatingPassword, setIsUpdatingPassword] = useState<boolean>(false)
  const [formMessage, setFormMessage] = useState<string>("")
  const [passwordData, setPasswordData] = useState<{
    newPassword: string;
    repeatPassword: string;
    newPasswordError: string;
    repeatPasswordError: string;
    passwordFormError: string;
  }>({
    repeatPassword: "",
    newPasswordError: "",
    repeatPasswordError: "",
    newPassword: "",
    passwordFormError: ""
  })

  const signOut = () => {
    userData.cognitoUser.signOut(() => {
      navigate("/signin")
    })
  }

  const changePassword = (oldPassword, newPassword) => {
    setIsUpdatingPassword(true)
    setPasswordData({
      ...passwordData,
      newPasswordError: "",
      repeatPasswordError: "",
      passwordFormError: ""
    })
    userData.cognitoUser.changePassword(oldPassword, newPassword, (error, result) => {
      setIsUpdatingPassword(false)
      if (error) {
        console.log(error)
        setPasswordData(
            {
              ...passwordData,
              passwordFormError: "An error occurred changing your password please enter the correct current password and try again"
            }
        )
      } else {
        setFormMessage("Your password has been changed successfully")
        setIsOpenPassDialog(false)
      }
    })
  }

  const onUpdatePassword = (e) => {
    e.preventDefault()
    const isValidPassword = doValidateForm()
    if (!isValidPassword) {
      return
    }
    const { newPassword } = passwordData
    changePassword(currentPassword, newPassword)
  }

  const onChangeNewPassword = (value) => {
    if (passwordData.newPasswordError && isValidPassword(value)) {
      setPasswordData({
        ...passwordData,
        newPassword: value,
        newPasswordError: ""
      })
    } else {
      setPasswordData({
        ...passwordData,
        newPassword: value,
      })
    }
  }

  const onNewPasswordBlur = () => {
    const { newPassword } = passwordData
    if (!isValidPassword(newPassword)) {
      setPasswordData({
        ...passwordData,
        newPasswordError: "New Password invalid, please see requirements",
      })
    } else {
      if (passwordData.newPasswordError) {
        setPasswordData({
          ...passwordData,
          newPasswordError: "",
        })
      }
    }
  }

  const doValidateForm = () => {
    const { newPassword } = passwordData
    let isValid = true
    if (!isValidPassword(newPassword)) {
      setPasswordData({
        ...passwordData,
        newPasswordError: "Password invalid, please see requirements",
      })
      isValid = false
    }
    if (!isRepeatPasswordValid()) {
      setPasswordData({
        ...passwordData,
        repeatPasswordError: "Repeat password must match new password",
      })
      isValid = false
    }
    return isValid
  }

  const isRepeatPasswordValid = () => {
    const { newPassword, repeatPassword } = passwordData
    return newPassword === repeatPassword
  }

  const passwordErrorMessageClass = "text-red-700 text-sm font-light text-center p-1 max-w-md"

  const getPasswordFormError = () => {
    if (passwordData.passwordFormError) {
      return (<div className={passwordErrorMessageClass}>{passwordData.passwordFormError}</div>)
    } else {
      return null
    }
  }

  const getRepeatPasswordError = () => {
    if (passwordData.repeatPasswordError) {
      return (<div className={passwordErrorMessageClass}>{passwordData.repeatPasswordError}</div>)
    } else {
      return null
    }
  }

  const getPasswordError = () => {
    if (passwordData.newPasswordError) {
      return (<div className={passwordErrorMessageClass}>{passwordData.newPasswordError}</div>)
    } else {
      return null
    }
  }

  const onChangeRepeatPassword = (value) => {
    if (passwordData.repeatPasswordError && value === passwordData.newPassword) {
      setPasswordData({
        ...passwordData,
        repeatPassword: value,
        repeatPasswordError: ""
      })
    } else {
      setPasswordData({
        ...passwordData,
        repeatPassword: value,
      })
    }
  }

  const getAccountFormMessage = () => {
    if (formMessage) {
      return (
          <div>
            <p className={"text-sm text-green-600 p-1 text-center pb-4"}>
              successfully changed password
            </p>
          </div>
      )
    }
    return null
  }

  return (
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div>
            <div>
              {getAccountFormMessage()}
            </div>
            <div className={"grid grid-cols-2 gap-2 pb-4"}>
              <div className={"text-gray-500 text-sm"}>Email:</div>
              <div>{email}</div>
              <div className={"text-gray-500 text-sm"}>Organization:</div>
              <div>{org}</div>
              <div className={"text-gray-500 text-sm"}>Name:</div>
              <div>{name}</div>
            </div>
            <div className={"grid grid-cols-2 gap-2 pb-2 pt-4"}>
              <div className={"text-left"}>
                <button
                    onClick={signOut}
                    className="inline rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Sign Out
                </button>
              </div>
              <div className={"text-right"}>
                <button
                    onClick={() => {
                      setIsOpenPassDialog(true)
                    }}
                    className="inline rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Change Password
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Dialog
            open={isOpenPassDialog}
            onClose={() => setIsOpenPassDialog(false)}
            className="relative z-50"
        >
          <div className="fixed inset-0 bg-black/30" aria-hidden="true"/>
          <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
            <Dialog.Panel className="rounded bg-white p-8">
              <Dialog.Title className={"text-center"}>Change Password</Dialog.Title>
              <Dialog.Description className={"max-w-md text-center text-sm text-gray-500 pt-3 pb-2"}>
                Passwords must be 8 characters in length, have 1 number,<br />
                1 special character, 1 lowercase letter, and 1 uppercase letter.
              </Dialog.Description>
              <div className={""}>
                <form>
                  <div>
                    {getPasswordError()}
                    {getRepeatPasswordError()}
                    {getPasswordFormError()}
                  </div>
                  <div className={"grid grid-cols-2 gap-2 pb-2 pt-4"}>
                    <div className={"text-gray-500 text-sm pt-2 mb-4"}>
                      <label htmlFor="current-password">Current Password:</label>
                    </div>
                    <div>
                      <input
                          name={"current-password"}
                          type="password"
                          autoComplete="current-password"
                          id={"current-password"}
                          required
                          className={"mb-4 block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"}
                          onChange={e => setCurrentPassword(e.target.value)}
                      />
                    </div>
                    <div className={"text-gray-500 text-sm pt-2 mb-4"}>
                      <label htmlFor="new-password">New Password:</label>
                    </div>
                    <div>
                      <input
                          name={"new-password"}
                          type="password"
                          id={"new-password"}
                          required
                          className={"mb-4 block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"}
                          onChange={e => onChangeNewPassword(e.target.value)}
                          onBlur={(e) => { onNewPasswordBlur() }}
                      />
                    </div>
                    <div className={"mb-4 text-gray-500 text-sm pt-2"}>
                      <label htmlFor="repeat-password">Repeat Password:</label>
                    </div>
                    <div>
                      <input
                          name={"repeat-password"}
                          type="password"
                          id={"repeat-password"}
                          required
                          className={"mb-4 block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"}
                          onChange={e => onChangeRepeatPassword(e.target.value)}
                      />
                    </div>
                    <div className={"text-left"}>
                      <button
                          onClick={onUpdatePassword}
                          disabled={isUpdatingPassword}
                          className={`${!isUpdatingPassword ? "bg-blue-600" :  "bg-blue-700"} inline rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
                      >
                        {isUpdatingPassword ? (
                                <Spinner className={"inline-block pr-1"} width={12} height={12} fill={"#eee"} />
                            ) : null}
                        Update
                      </button>
                    </div>
                    <div className={"text-right"}>
                      <button
                          onClick={() => {
                            setPasswordData({
                              repeatPassword: "",
                              newPasswordError: "",
                              repeatPasswordError: "",
                              newPassword: "",
                              passwordFormError: ""
                            })
                            setIsOpenPassDialog(false)
                          }}
                          className="inline rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </Dialog.Panel>
          </div>
        </Dialog>
      </div>
    </div>
  )
}

export default MyAccount;