import * as React from "react";
import type { PageProps } from "gatsby";
import BaseLayout from "../components/layout/BaseLayout";
import MyAccount from "../components/user/MyAccount";
import WithAuth from "../components/user/WithAuth";

const AccountRoute = (props: PageProps) => {
  return (
    <WithAuth>
      <BaseLayout {...props}>
        <MyAccount />
      </BaseLayout>
    </WithAuth>
  );
};

export const Head = () => (
  <>
    <title>Runic | My Account</title>
  </>
)


export default AccountRoute;
